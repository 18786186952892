// VideoOverlay.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import SimplePeer from 'simple-peer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { LOCAL_VIDEO_CONSTRAINTS, SERVER_VIDEO_CONSTRAINTS } from '../config/videoConfig';

const FADE_DURATION = 500; // Durée du fade en ms

const VideoOverlay = ({ 
  websocket, 
  inventorySessionId, 
  onVideoReady, 
  userContext,
  onStatsUpdate
}) => {
  // Refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const peerRef = useRef(null);
  const lastDetectionsRef = useRef({ zones: [], boxes: [] });

  // États
  const [isLoading, setIsLoading] = useState(true);
  const [videoReady, setVideoReady] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({});
  const [currentDetections, setCurrentDetections] = useState({
    zones: [],
    boxes: []
  });
  const [fadingElements, setFadingElements] = useState({
    zones: [],
    boxes: []
  });

  // Hook WebSocket personnalisé
  const { subscribe, sendMessage } = useWebSocket();

  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());

  // Gestion du fade-out
  const handleFadeOut = useCallback((type, newElements) => {
    const currentIds = new Set(newElements.map(e => e.id));
    const previousElements = lastDetectionsRef.current[type];
    
    // Identifier les éléments qui ont disparu
    const disappearingElements = previousElements.filter(elem => !currentIds.has(elem.id));
    
    if (disappearingElements.length > 0) {
      setFadingElements(prev => ({
        ...prev,
        [type]: [
          ...prev[type],
          ...disappearingElements.map(elem => ({
            ...elem,
            startFade: Date.now()
          }))
        ]
      }));
    }
    
    // Mettre à jour la référence
    lastDetectionsRef.current[type] = newElements;
  }, []);

  // Configuration WebRTC
  const initializePeerConnection = useCallback(async () => {
    try {
      const serverStream = await navigator.mediaDevices.getUserMedia({
        video: SERVER_VIDEO_CONSTRAINTS,
        audio: false
      });

      const localStream = await navigator.mediaDevices.getUserMedia({
        video: LOCAL_VIDEO_CONSTRAINTS,
        audio: false
      });

      if (videoRef.current) {
        videoRef.current.srcObject = localStream;
        streamRef.current = localStream;
      }

      const peer = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: serverStream,
        config: { 
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' }
          ] 
        }
      });

      peerRef.current = peer;

      peer.on('signal', data => {
        if (data.type === 'offer') {
          sendMessage({
            type: 'offer',
            sdp: data.sdp,
            inventory_session_id: inventorySessionId,
            user_context: userContext
          });
        }
      });

      peer.on('connect', () => {
        setIsConnected(true);
      });

      peer._pc.addEventListener('iceconnectionstatechange', () => {
        const connectionState = peer._pc.iceConnectionState;
        if (connectionState === 'connected' || connectionState === 'completed') {
          setIsLoading(false);
          setVideoReady(true);
        }
      });

      peer.on('error', err => {
        console.error('Peer error:', err);
        setIsLoading(false);
      });

      return peer;
    } catch (error) {
      console.error('Error initializing peer connection:', error);
      setIsLoading(false);
      throw error;
    }
  }, [inventorySessionId, sendMessage, userContext]);

  // Nettoyage des éléments en fade
  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      setFadingElements(prev => {
        const newZones = prev.zones.filter(zone => now - zone.startFade < FADE_DURATION);
        const newBoxes = prev.boxes.filter(box => now - box.startFade < FADE_DURATION);
  
        return { zones: newZones, boxes: newBoxes };
      });
  
      // Vérifier s'il y a un délai sans nouvelles données
      // Si plus de X ms sans update et que currentDetections n'est pas vide, on déclenche fadeOut
      const NO_UPDATE_TIMEOUT = 1000; // Par exemple 1 secondes sans données
      if (now - lastUpdateTime > NO_UPDATE_TIMEOUT) {
        // Pas de nouvelle détection depuis un moment, tout doit disparaître
        if (currentDetections.zones.length > 0) {
          handleFadeOut('zones', []); 
          setCurrentDetections(prev => ({ ...prev, zones: [] }));
        }
        if (currentDetections.boxes.length > 0) {
          handleFadeOut('boxes', []);
          setCurrentDetections(prev => ({ ...prev, boxes: [] }));
        }
      }
    }, 100);
  
    return () => clearInterval(cleanupInterval);
  }, [currentDetections, lastUpdateTime, handleFadeOut]);

  // Souscription aux messages WebSocket
  useEffect(() => {
    if (!websocket) return;

    const unsubscribes = [
      // Gestion des zones actives
      subscribe('active_zones', (data) => {
        if (!data || !data.zones || !Array.isArray(data.zones)) {
          console.warn('Invalid active_zones message:', data);
          return;
        }
      
        if (data.image_dimensions) {
          setImageDimensions(data.image_dimensions);
        }
      
        const transformedZones = data.zones
          .filter(zone => zone.dimensions)
          .map(zone => ({
            id: zone.shelf_id,
            name: zone.shelf_name,
            rack_id: zone.rack_id,
            x: zone.dimensions.x,
            y: zone.dimensions.y,
            width: zone.dimensions.width,
            height: zone.dimensions.height
          }));

        handleFadeOut('zones', transformedZones);
        
        setCurrentDetections(prev => ({
          ...prev,
          zones: transformedZones
        }));
        setLastUpdateTime(Date.now());
      }),

      // Gestion des détections d'objets
      subscribe('detection_coordinates', (data) => {
        if (data.image_dimensions) {
          setImageDimensions(data.image_dimensions);
        }

        if (data.bounding_boxes) {
          const transformedBoxes = data.bounding_boxes.map(box => ({
            ...box,
            id: box.id
          }));

          handleFadeOut('boxes', transformedBoxes);

          setCurrentDetections(prev => ({
            ...prev,
            boxes: transformedBoxes
          }));
        }
        setLastUpdateTime(Date.now());
      }),

      // Gestion des stats du stream
      subscribe('stream_stats', (data) => {
        if (data.data) {
          const stats = {
            fps: data.data.fps,
            bitrate: data.data.bitrate,
            resolution: data.data.resolution,
            latency: data.data.latency
          };
          onStatsUpdate(stats);
        }
      }),

      // Gestion de la réponse SDP
      subscribe('answer', async (data) => {
        try {
          if (peerRef.current && !isConnected) {
            await peerRef.current.signal(data);
          }
        } catch (error) {
          console.error('Error handling answer:', error);
        }
      })
    ];

    return () => unsubscribes.forEach(unsub => unsub());
  }, [websocket, onStatsUpdate, handleFadeOut]);

  // Initialisation de la connexion vidéo
  useEffect(() => {
    if (inventorySessionId && websocket) {
      initializePeerConnection().catch(console.error);
    }

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      if (peerRef.current) {
        peerRef.current.destroy();
      }
    };
  }, [inventorySessionId, websocket, initializePeerConnection]);

  // Fonction de rendu d'un élément avec fade
  const drawElement = useCallback((ctx, element, type, baseOpacity = 0.8) => {
    const videoRect = videoRef.current.getBoundingClientRect();
    const scaleX = videoRect.width / imageDimensions.width;
    const scaleY = videoRect.height / imageDimensions.height;

    const x = element.x * scaleX;
    const y = element.y * scaleY;
    const width = element.width * scaleX;
    const height = element.height * scaleY;

    let opacity = baseOpacity;
    if (element.startFade) {
      const fadeProgress = (Date.now() - element.startFade) / FADE_DURATION;
      opacity = baseOpacity * (1 - fadeProgress);
    }

    if (type === 'zone') {
      // Dessiner les zones en blanc
      ctx.fillStyle = `rgba(255, 255, 255, ${opacity * 0.1})`;
      ctx.fillRect(x, y, width, height);

      ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);

      if (element.name) {
        const fontSize = Math.max(12, Math.min(16, width / 8));
        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom'; 
        
        const text = element.name;
        const textMetrics = ctx.measureText(text);
        const padding = 4;
        const textHeight = fontSize + padding * 2;
  
        // Fond du texte
        ctx.fillStyle = `rgba(0, 0, 0, ${opacity * 0.5})`;
        ctx.fillRect(
          x + width/2 - textMetrics.width/2 - padding,
          y + height - textHeight,
          textMetrics.width + padding * 2,
          textHeight
        );
        
        // Texte en blanc
        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.fillText(text, x + width/2, y + height - padding);
      }
    } else {
      // Déterminer la couleur selon expected_in_zone
      let baseColor;
      if (element.expected_in_zone === true) {
        baseColor = [0, 255, 0]; // Vert
      } else if (element.expected_in_zone === false) {
        baseColor = [255, 0, 0]; // Rouge
      } else {
        baseColor = [255, 165, 0]; // Orange
      }

      // Couleur de remplissage translucide
      ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity * 0.2})`;
      ctx.fillRect(x, y, width, height);

      // Couleur de remplissage translucide
      ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity * 0.2})`;
      ctx.fillRect(x, y, width, height);

      if (element.class_name) {
        const fontSize = Math.max(12, Math.min(14, videoRect.width / 40));
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'left';
  
        const text = `${element.class_name} ${Math.round(element.confidence * 100)}%`;
        const textMetrics = ctx.measureText(text);
        const textPadding = 3;
  
        // Fond du texte
        ctx.fillStyle = `rgba(0, 0, 0, ${opacity * 0.7})`;
        ctx.fillRect(
          x,
          y - fontSize - textPadding * 2,
          textMetrics.width + textPadding * 2,
          fontSize + textPadding * 2
        );

        // Texte
        ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
        ctx.fillText(text, x + textPadding, y - textPadding);
      }
    }
  }, [imageDimensions]);

  // Rendu du canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video || !videoReady) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const draw = () => {
      const videoRect = video.getBoundingClientRect();
      
      if (canvas.width !== videoRect.width || canvas.height !== videoRect.height) {
        canvas.width = videoRect.width;
        canvas.height = videoRect.height;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (!imageDimensions.width || !imageDimensions.height) return;

      // Dessiner les éléments actifs
      currentDetections.zones.forEach(zone => drawElement(ctx, zone, 'zone'));
      currentDetections.boxes.forEach(box => drawElement(ctx, box, 'box'));

      // Dessiner les éléments en fade-out
      fadingElements.zones.forEach(zone => drawElement(ctx, zone, 'zone'));
      fadingElements.boxes.forEach(box => drawElement(ctx, box, 'box'));

      requestAnimationFrame(draw);
    };

    const animationId = requestAnimationFrame(draw);
    return () => cancelAnimationFrame(animationId);
  }, [currentDetections, fadingElements, imageDimensions, videoReady, drawElement]);

  return (
    <div 
      id="videoContainer" 
      style={{
        position: 'relative',
        width: '100%',
        height: '200px',
        overflow: 'hidden',
        backgroundColor: '#000',
      }}
    >
      {isLoading && (
        <div className="spinner-container">
          <div className="pl">
            <svg 
              className="pl__ring" 
              cx="88" 
              cy="96" 
              r="85" 
              fill="none" 
              stroke="url(#pl-grad)" 
              strokeWidth="4" 
              strokeLinecap="round" 
            />
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className={videoReady ? '' : 'is-hidden'}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 'auto',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          objectFit: 'contain',
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: 'auto',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          objectFit: 'contain',
        }}
      />
    </div>
  );
};

export default VideoOverlay;